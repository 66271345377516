<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewSymptoms">New Department</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
       :responsive="false">
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     
     <template #edrsDepartmentName="{item}">
        <td class='font-weight-bold'
          v-if="item.edrsDepartmentName"
        >{{ item.edrsDepartmentName }}</td>
        <td v-else>--</td>
      </template>
       <!-- <template #userType="{item}">
        <td
          v-if="item.userType == 1">
            Doctor  </td>
          <td v-else>No </td>
       
        
      </template>   -->
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'Department', params: {departmentId: item.departmentId,subGroupId: item.subGroupId, mode: 'view'}}">View </CDropdownItem>
               <!-- <CDropdownItem :to="{name: 'JobTypes', params: {departmentId: item.departmentId, mode: 'view'}}">Job Types </CDropdownItem> -->
                <CDropdownItem :to="{name: 'Department Users', params: {departmentId: item.departmentId,subGroupId: item.subGroupId, mode: 'view'}}">Department Users </CDropdownItem>
                <CDropdownItem :to="{name: 'Department Categories', params: {departmentId: item.departmentId,subGroupId: item.subGroupId}}">Categories</CDropdownItem>
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteDepartment(item.departmentId)"
                    ></ConfirmationModal>

                </CDropdown>

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
<CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  { key: "departmentName",  _classes: 'font-weight-bold',   _style: "min-width:200px" },
   { key: "edrsDepartmentName",label: "EDRS Department Name", _classes: 'font-weight-bold',   _style: "min-width:200px" },
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold',   _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Departments",
   components: {
    ConfirmationModal,
   },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getDepartments() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/departments/"+this.$route.params.subGroupId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    //  movetrash(faqId) {
    //  this.apiGetSecure(process.env.VUE_APP_API_HOST + "/web/secure/delete/faq/" + faqId, {
    //     method: "GET",
    //     // headers: this.defaultHeaders()
    //   });
    //  this.getSymptoms();
    //   this.$nextTick(() => {
    //     this.$refs.vuetable.refresh();
    //   });
    //   window.location.reload();
    // },
      cancel() {
    
             this.$router.push({ name: "Sub Groups",params: {groupId: this.$route.params.groupId}});
    },
    deleteDepartment(departmentId) {
      console.log("delete called with", departmentId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/department/" +
         departmentId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getDepartments();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
           window.location.reload();
        });
    },
    navToNewSymptoms(){
       this.$router.push({ name: "NewDepartment", params: {subGroupId: this.$route.params.subGroupId, mode: "new" } });
    }
  },
  mounted() {
    this.getDepartments();
  },
};
</script>